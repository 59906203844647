import { FormikProvider, useFormik } from "formik";
import { Meta } from "../../types/content-types";
import styles from "./Form.module.css";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { createMap, deleteMap, updateMap } from "../../common/ApiService";

import { Select, TextArea, TextField } from "../Components";

import {
  removeEmptyProps,
  validateJSON,
  validateNotEmpty,
} from "../../common/utils";

import { useEffect } from "react";
import { getMapThunk, getMapsListThunk } from "../../redux/map";
import Button from "../../components/Button/Button";

type MapConfigForm = {
  map_config: string;
  title: string;
  selectedMapId?: string;
} & Partial<Meta>;

const initialValues: MapConfigForm = {
  map_config: "",
  title: "",
  selectedMapId: undefined,
};

const validate = (values: MapConfigForm) => {
  const errors = {
    map_config:
      validateNotEmpty(values.map_config) ?? validateJSON(values.map_config),
    title: validateNotEmpty(values.title),
  };
  return removeEmptyProps(errors);
};

export default function AddMapForm() {
  const { mapsList, isMapLoading } = useSelector(
    (state: RootState) => state.map
  );

  const dispatch = useDispatch<AppDispatch>();

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => onSubmit(values),
  });

  async function onSubmit(values: MapConfigForm) {
    const valuesCopy = JSON.parse(JSON.stringify(values));
    try {
      valuesCopy.map_config = JSON.parse(valuesCopy.map_config);
      valuesCopy.map_config.title = values.title;
    } catch (error) {
      alert("Некорректный JSON " + error);
      return;
    }

    if (valuesCopy.selectedMapId) {
      updateMap(valuesCopy.map_config)
        .then((id) => {
          alert(`Слой обновлен. id: ${id}`);
          dispatch(getMapsListThunk());
        })
        .catch((err) => {
          alert("Слой не опубликован! " + err);
        });
    } else {
      createMap(valuesCopy.map_config)
        .then((map) => {
          alert(`Слой опубликован. id: ${map.id}`);
          resetForm();
          dispatch(getMapsListThunk());
        })
        .catch((err) => {
          alert("Слой не опубликован! " + err);
        });
    }
  }

  const {
    values,
    errors,
    touched,
    isValid,
    resetForm,
    setFieldValue,
    handleChange,
  } = formik;

  const handleDeleteMap = () => {
    const res = window.confirm("Удаляем слой?");
    if (res) {
      if (values.selectedMapId) {
        deleteMap(values.selectedMapId)
          .then(() => {
            alert("Слой удален");
            dispatch(getMapsListThunk());
            resetForm();
          })
          .catch((err) => {
            alert(`Слой не удален по причине ошибки: ${err}`);
          });
      }
    }
  };

  const handleMapIdChange = (e: any) => {
    const selectedMapId = e.target.value;
    if (selectedMapId === "new") {
      resetForm();
    } else {
      dispatch(getMapThunk(selectedMapId)).then((data) => {
        setFieldValue("map_config", JSON.stringify(data.payload));
      });
      const targetMap = mapsList.find((map) => map.id === selectedMapId);
      setFieldValue("title", targetMap?.title);
      setFieldValue("selectedMapId", selectedMapId);
    }
  };

  useEffect(() => {
    dispatch(getMapsListThunk());
  }, [dispatch]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.container}>
          <Select
            onChange={handleMapIdChange}
            options={mapsList || []}
            name="selectedMapId"
            label="Слой"
            value={values.selectedMapId}
            showIds
            isNewOptionAvailable
            isChooseOptionFirst={false}
          />

          <h4 className={styles.heading}>Редактор слоя</h4>

          <TextField
            label="Код, название слоя"
            onChange={handleChange}
            value={values.title}
            name="title"
            isValid={!touched.title || !errors.title}
            validationMessage={errors.title}
          />

          <TextArea
            label="JSON"
            rows={10}
            name={`map_config`}
            onChange={handleChange}
            value={values.map_config}
            placeholder={isMapLoading ? "Загрузка..." : ""}
            isValid={!touched.map_config || !errors.map_config}
            validationMessage={errors.map_config}
          />

          <Button
            type="submit"
            title={values.selectedMapId ? "Обновить слой" : "Добавить слой"}
          />

          {values.selectedMapId && (
            <Button
              type="button"
              onClick={handleDeleteMap}
              style={{ background: "red" }}
              title="Удалить слой"
            />
          )}
          {!isValid && <div style={{ color: "red" }}>Проверьте поля</div>}
        </div>
      </form>
    </FormikProvider>
  );
}
