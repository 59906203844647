import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import MapControls from "../components/MapControls/MapControls";

import {
  getConstructorBaseMapThunk,
  getConstructorLayersThunk,
  setClusterId,
  setDefaultMap,
} from "../redux/map";
import { openSidebar } from "../redux/menu";
import { AppDispatch, RootState } from "../store";
import Main from "../components/Map/MapOverLayer";
import styles from "../components/Map/Map.module.css";
import Sidebar from "../components/SideBar/Sidebar";
import { setPostNull } from "../redux/post";
import { useSidebar } from "../components/SideBar/useSidebar";

import { ContentBlock } from "../types/content-types";
import { Layer } from "../components/Map/config/interfaces";
import {
  setIsConstructorMode,
  useConstructorMode,
} from "../redux/constructorMode";

import Constructor from "../components/Constructor/Constructor";

export type AbstractLayer = {
  chapter_title: string;
  post_title: string;
  content_title: string;
  content_block: ContentBlock;
  map_id: string;
  map_layers: Layer[];
};

export default function ConstructorPage() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isConstructorMode = useSelector(useConstructorMode);

  const { isSidebarOpen, clusterId, isMenu } = useSidebar();
  const { id: paramClusterId } = useParams();

  const goBackToMenu = useCallback(() => {
    navigate("/constructor");
    dispatch(setDefaultMap());
    dispatch(setPostNull());
  }, [dispatch, navigate]);

  const onSidebarClose = useCallback(() => {
    dispatch(openSidebar(!isSidebarOpen));
  }, [dispatch, isSidebarOpen]);

  useEffect(() => {
    if (!isConstructorMode) {
      dispatch(setIsConstructorMode(true));
    }
  }, [isConstructorMode, dispatch]);

  useEffect(() => {
    if (paramClusterId) {
      dispatch(setClusterId(paramClusterId));
    }
  }, [dispatch, paramClusterId]);

  useEffect(() => {
    if (clusterId) {
      dispatch(getConstructorBaseMapThunk(clusterId)).then(() => {
        dispatch(getConstructorLayersThunk(clusterId));
      });
    }
  }, [clusterId, dispatch]);

  return (
    <>
      <Main>
        <MapControls />
        <div className={styles.containerLeft} />
        <div className={styles.containerRight}>
          <Sidebar
            isMenu={isMenu}
            onGoBack={goBackToMenu}
            isSidebarOpen={isSidebarOpen}
            onSidebarClose={onSidebarClose}
            Content={<Constructor />}
            menuType={"constructor"}
            scrollable={false}
          />
        </div>
      </Main>
    </>
  );
}
