import "./App.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Map from "./components/Map/Map";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

import Routing from "./Routing";
import CapScreen from "./components/CapScreen/CapScreen";
import { getTokensFromStorage } from "./auth/tokenService";
import { AppDispatch, RootState } from "./store";
import { useEffect } from "react";
import { getUserInfoThunk } from "./redux/auth/auth.reducer";
import GlobeMap from "./components/GlobeMap/GlobeMap";
import ModalWindowContainer from "./components/ModalWindow/ModalWindowContainer";

function App() {
  const location = useLocation();

  const isScreenWideEnough = useMediaQuery({ query: "(min-width: 1000px)" });

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const userInfo = useSelector((store: RootState) => store.auth.userInfo);

  function MapToRender() {
    // if (location.pathname === "/map") {
    //   return <GlobeMap />;
    // } else {
    //   return <Map />;
    // }
    return <Map />;
  }

  useEffect(() => {
    const tokens = getTokensFromStorage();
    if (tokens) {
      dispatch(getUserInfoThunk());
    }
  }, [dispatch]);

  useEffect(() => {
    if (userInfo && userInfo.verified === false) {
      if (location.pathname !== "/login/confirm") {
        navigate("/login/confirm");
      }
    }
  }, [userInfo, navigate, location.pathname]);

  if (location.pathname === "/live/check/a6d6e57d") {
    return <CapScreen title='{"alive": true}' />;
  }

  return (
    <div className="App">
      {isScreenWideEnough ? (
        <>
          <MapToRender />
          <Routing />
          <ModalWindowContainer />
        </>
      ) : (
        <CapScreen title="РАЗРЕШЕНИЕ ЭКРАНА НЕ ПОДДЕРЖИВАЕТСЯ" />
      )}
    </div>
  );
}

export default App;
