import React, { useEffect } from "react";
import Select from "../Input/Select/Select";
import { useSelector, useDispatch } from "react-redux";
import { setClusterId, setChapterId } from "../../redux/map";
import { RootState } from "../../store";

export default function MenuCluster() {
  const { data: menu } = useSelector((state: RootState) => state.menu);
  const clustersOptions = menu?.clusters || [];

  const { clusterId } = useSelector((state: RootState) => state.map);
  const dispatch = useDispatch();

  const handleClustersOption = (value: string) => {
    dispatch(setClusterId(value));
    dispatch(setChapterId(null));
  };

  const isConstructorMode = useSelector(
    (state: RootState) => state.constructorMode.isConstructorMode
  );

  useEffect(() => {
    if (isConstructorMode) {
      dispatch(setClusterId(clusterId));
    }
  }, [clusterId, dispatch, isConstructorMode]);

  return (
    <Select
      options={clustersOptions}
      value={clusterId}
      placeholder={"Выберите кластер"}
      onSelectedChange={handleClustersOption}
    />
  );
}
