import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./ListMenu.module.scss";
import { MenuItem } from "../../types/content-types";
import LinkElement from "../LinkElement/LinkElement";

export interface ListMenuProps {
  clusterId: string | null;
  clustersCount: number;
  chapters: MenuItem[] | [];
  posts: MenuItem[];
  isPostsListLoading: boolean;
  goToPostById: (postId: string) => void;
  handleLoginNavigate: () => void;
}

const ListMenu: React.FC<ListMenuProps> = ({
  clusterId,
  clustersCount,
  chapters,
  posts,
  isPostsListLoading,
  goToPostById,
  handleLoginNavigate,
}) => {
  const [openLists, setOpenLists] = useState<{ [key: string]: Set<string> }>(
    {}
  );
  const [height, setHeight] = useState<number[]>([]);
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const updatedOpenLists: { [key: string]: Set<string> } = {};
    for (let i = 1; i <= clustersCount; i++) {
      updatedOpenLists[i] = new Set();
    }
    setOpenLists(updatedOpenLists);
  }, [clustersCount, clusterId]);

  const toggleList = useCallback((clusterId: string | null, listId: string) => {
    if (clusterId !== null) {
      setOpenLists((prevOpenLists) => {
        const updatedLists = { ...prevOpenLists };
        if (prevOpenLists[clusterId]?.has(listId)) {
          const updatedSet = new Set(prevOpenLists[clusterId]);
          updatedSet.delete(listId);
          updatedLists[clusterId] = updatedSet;
        } else {
          const updatedSet = new Set(prevOpenLists[clusterId]);
          updatedSet.add(listId);
          updatedLists[clusterId] = updatedSet;
        }
        return updatedLists;
      });
    }
  }, []);

  useEffect(() => {
    const heightArray = chapters?.map((_, index) => {
      const element = refs.current[index];
      return element ? element.clientHeight : 0;
    });
    setHeight(heightArray);
  }, [chapters]);

  const lockedChapters = chapters.map((chapter) =>
    posts
      .filter((post) => post.chapter_id === chapter.id)
      .every((post) => !post.id)
  );

  return (
    <div className={styles.mainContainer}>
      {!isPostsListLoading &&
        chapters?.map((chapter, index) => (
          <div
            key={`${clusterId}_${chapter.id}`}
            className={styles.listMenuContainer}
          >
            <div
              className={
                clusterId !== null
                  ? openLists[clusterId]?.has(chapter.id)
                    ? styles.chapterContainerOpen
                    : styles.chapterContainer
                  : styles.chapterContainer
              }
              onClick={() => toggleList(clusterId, chapter.id)}
            >
              <div
                className={
                  lockedChapters[index]
                    ? styles.lockСlusterText
                    : styles.clusterText
                }
              >
                {chapter.title}
              </div>
              <div
                className={
                  clusterId !== null
                    ? openLists[clusterId]?.has(chapter.id)
                      ? styles.detailsIconUp
                      : styles.detailsIconDown
                    : styles.detailsIconDown
                }
              />
            </div>
            <div
              className={styles.transition}
              style={{
                maxHeight:
                  clusterId !== null
                    ? openLists[clusterId]?.has(chapter.id)
                      ? height[index]
                      : 0
                    : 0,
                transitionDuration: `${
                  height[index] > 350 ? 1150 : height[index] * 3
                }ms`,
              }}
            >
              <div
                ref={(element) => (refs.current[index] = element)}
                className={styles.listContainer}
              >
                {posts
                  .filter((post) => post.chapter_id === chapter.id)
                  ?.map((post) => (
                    <LinkElement
                      key={`${clusterId}_${chapter.id}_${post.id}`}
                      postId={post.id}
                      postTitle={post.title}
                      accessedLink={!!post.id}
                      isDocsType={post.type === 2}
                      fileLink={post.url}
                      goToPostById={goToPostById}
                      handleLoginNavigate={handleLoginNavigate}
                    />
                  ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ListMenu;
