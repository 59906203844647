import { Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Select, TextField } from "../Components";
import {
  clearEmptyFields,
  removeEmptyProps,
  validateNotEmpty,
} from "../../common/utils";
import styles from "./Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import Button from "../../components/Button/Button";
import { Post } from "../../types/content-types";
import { getPostThunk } from "../../redux/post";
import { getPostsListThunk } from "../../redux/menu";
import { selectPost } from "../../redux/post/selectors";

import {
  createPost,
  deletePost,
  updatePost,
  uploadFile,
} from "../../common/ApiService";
import { cloneDeep } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import MapLoader from "../../components/Loaders/MapLoader/MapLoader";

export type PostFile = Post & { file: File | null; url?: string };

const initialValues: PostFile = {
  id: undefined,
  file: null,
  chapter_id: "",
  cluster_id: "",
  title: "",
  type: 2,
  content_blocks: [],
};

const validate = (values: PostFile) => {
  const errors: Record<string, any> = {
    cluster_id: validateNotEmpty(values.cluster_id),
    chapter_id: validateNotEmpty(values.chapter_id),
    title: validateNotEmpty(values.title),
    // file: validateFileInput(values.file),
  };

  return removeEmptyProps(errors);
};

const prepareValues = (values: PostFile): PostFile => {
  const valuesCopy = cloneDeep(values);

  const clearedValues = clearEmptyFields(valuesCopy);

  return clearedValues;
};

export default function AddFileForm() {
  const [file, setFile] = useState<File>();
  const [isUploading, setIsUploading] = useState(false);
  const { id } = useParams<string>();
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      const prepVals = prepareValues(values);
      if (values.id) {
        submitUpdatePost(prepVals);
      } else {
        submitNewPost(prepVals);
      }
    },
  });

  const dispatch = useDispatch<AppDispatch>();

  const { data: menu, postList: PostListRaw } = useSelector(
    (state: RootState) => state.menu
  );
  const { data: post } = useSelector(selectPost);

  const navigate = useNavigate();

  const postList = PostListRaw?.filter((post) => post.type === 2);
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setValues,
    setFieldValue,
  } = formik;

  const getPosts = useCallback(() => {
    dispatch(
      getPostsListThunk({
        chapterId: values.chapter_id,
        clusterId: values.cluster_id,
      })
    );
  }, [dispatch, values.chapter_id, values.cluster_id]);

  function submitNewPost(values: PostFile) {
    createPost(values)
      .then((data) => {
        alert(`Пост опубликован. id: ${data.id}`);
        if (file && data.id) {
          setIsUploading(true);
          uploadFile({ file, postId: String(data.id) })
            .then((post) => {
              alert(`Файл загружен`);
              setIsUploading(false);
              setValues((values) => ({ ...values, ...post }));
            })
            .finally(() => {
              setIsUploading(false);
              navigate(`/edit/file/${data.id}`);
            });
        }
        if (data.id) dispatch(getPostThunk(data.id!));
      })
      .catch((err) => {
        alert("Пост не опубликован. Ошибка: " + err);
      });
  }

  function submitUpdatePost(values: PostFile) {
    updatePost(values)
      .then(() => {
        alert(`Пост обновлен`);
        if (file) {
          uploadFile({ file, postId: String(values.id) })
            .then(() => {
              alert(`Файл загружен`);
            })
            .then((data) => {
              navigate(`/edit/file/${values.id}`);
            });
        }
      })
      .catch((err) => {
        alert("Пост не обновлен. Ошибка: " + err);
      });
  }

  const handlePostChange: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      (e) => {
        resetForm();
        const value = e.target.value ? e.target.value : "";
        setFieldValue(e.target.name, value);
        navigate(`/edit/file${value ? "/" + value : ""}`);
      },

      [navigate, resetForm, setFieldValue]
    );

  const handleDeletePost = useCallback(() => {
    const res = window.confirm("Удаляем пост?");
    if (res === true) {
      deletePost(values.id!)
        .then((data) => {
          alert(`Пост удален ${data.id}`);
          getPosts();
          resetForm();
        })
        .catch((err) => alert(`Пост не был удален, ${err}`));
    }
  }, [getPosts, resetForm, values.id]);

  useEffect(() => {
    if (post) {
      setValues((values) => ({ ...values, ...post }));
    }
  }, [post, setValues]);

  useEffect(() => {
    getPosts();
  }, [getPosts, values.chapter_id, values.cluster_id]);

  useEffect(() => {
    if (values.id) {
      dispatch(getPostThunk(values.id));
    }
  }, [dispatch, values.id]);

  useEffect(() => {
    if (id) {
      dispatch(getPostThunk(id));
    }
  }, [dispatch, id]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className={styles.container}>
          <h4 className={styles.heading}>Загрузка файла</h4>

          <Select
            isValid={!touched.cluster_id || !errors.cluster_id}
            validationMessage={errors.cluster_id}
            onChange={handleChange}
            options={menu?.clusters || []}
            name="cluster_id"
            label="Кластер(ВУЗ)"
            value={values.cluster_id}
          />
          <Select
            isValid={!touched.chapter_id || !errors.chapter_id}
            validationMessage={errors.chapter_id}
            onChange={handleChange}
            options={menu?.chapters || []}
            name="chapter_id"
            label="Раздел"
            value={values.chapter_id}
          />
          <Select
            isValid={!touched.id || !errors.id}
            validationMessage={errors.id}
            onChange={handlePostChange}
            options={postList || []}
            name="id"
            label={`PDF файл (${postList?.length ?? null})`}
            value={values.id}
            showIds
            isChooseOptionFirst={true}
          />
          <TextField
            name="title"
            label="Название"
            onChange={handleChange}
            value={values.title}
            isValid={!touched.title || !errors.title}
            validationMessage={errors.title}
          />
          {values.url ? (
            <>
              Ссылка на загруженный файл: <br />{" "}
              <a href={values.url} target="_blank" rel="noreferrer">
                {values.url}
              </a>
            </>
          ) : null}

          <input
            id="file"
            name="file"
            type="file"
            accept="application/pdf"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.currentTarget.files)
                setFile(event.currentTarget.files[0]);
            }}
          />

          {isUploading && <MapLoader />}

          <Button
            type="submit"
            title="Отправить"
            status={isUploading ? "disabled" : "enabled"}
          />

          {values.id && (
            <div className={styles.containerRight}>
              <Button
                type="button"
                onClick={handleDeletePost}
                status={!values.id ? "disabled" : "enabled"}
                style={{ background: "red" }}
                title="Удалить pdf-пост"
              />
            </div>
          )}
        </div>
      </Form>
    </FormikProvider>
  );
}
